import {Container} from "react-bootstrap";

const NotFound = () => {
    return (
        <Container className={"text-center"} fluid>
        <h1>404 not found</h1>
        </Container>
    )
}

export default NotFound;